<template>
  <Form @newData="dataHandler" v-if="!responseData" />
  <Result v-else :responseData="responseData" />
</template>

<script>
import Result from "@/components/Result.vue";
import Form from "@/components/Form.vue";

export default {
  components: {Result, Form},
  data() {
    return {
      responseData: null
    }
  },
  methods: {
    dataHandler(newData) {
      this.responseData = newData;
    }
  }
}
</script>